<template>
    <div>
        <div class="page--service container container-l mx-auto">
            <!-- <section>
				<div class="hero">
					<div class="hero-content">
						<h2>{{ service.title }}</h2>
						<p>{{ service.discription }}</p>
					</div>
					<figure class="hero-img">
						<img :src="service.main_midea" alt="" />
						<img src="@/assets/images/Hosting.svg" alt="" />
					</figure>
				</div>
			</section> -->
            <section>
                <hero
                    :title="service.title"
                    :text="service.discription"
                >
                    <!-- <img :src="service.main_midea" alt="" /> -->
                    <img
                        class="mx-auto"
                        src="@/assets/images/Hosting.svg"
                        alt=""
                    />
                </hero>
            </section>
            <section
                v-if="service.tecnologies.length"
                class="tecnology-section"
            >
                <h2 class="title text-2xl sm:text-4xl xl:text-6xl">
                    {{ $t("headings.usedTechnologies") }}
                </h2>
                <!-- <div class="tecnology-container">
					<div
						class="tecnology"
						v-for="tecnology in service.tecnologies"
						:key="tecnology.id"
					>
						<figure class="">
							<img class="" :src="require(tecnology.media)" alt="" />
							<img class="" src="@/assets/images/tec1.svg" alt="" />
						</figure>
						<span>{{ tecnology.name }}</span>
					</div>
				</div> -->
                <div>
                    <tecnologies :tecnologies="service.tecnologies" />
                    <!-- <div
						class="tecnology-container"
						v-for="(chunk, index) in chunks"
						:key="index"
					>
						<div
							class="tecnology"
							v-for="tecnology in chunk"
							:key="tecnology.id"
						>
							<figure class="">
								<img class="" :src="require(tecnology.media)" alt="" />
								<img class="" src="@/assets/images/tec1.svg" alt="" />
							</figure>
							<span>{{ tecnology.name }}</span>
						</div>
					</div> -->
                </div>
            </section>
            <section
                v-if="service.sections.length"
                class="packages-section"
            >
                <div class="container mx-auto">
                    <ul class="packages-header">
                        <li
                            v-for="section in service.sections"
                            :key="section.id"
                            class="section-details"
                            :id="`section-${section.id}`"
                        >
                            <button @click="changeSection(section)">
                                {{ section.name }}
                            </button>
                        </li>
                    </ul>
                    <div
                        v-if="selectedSection"
                        class="packages-content"
                    >
                        <p class="package-description">
                            <span>
                                {{ selectedSection.discription }}
                            </span>
                        </p>
                        <div
                            class="package-types"
                            v-if="selectedSection.types.length === 4"
                        >
                            <div
                                v-for="pack in selectedSection.types"
                                :key="pack.id"
                                class="package-type-container"
                            >
                                <div class="package-type">
                                    <figure class="package-icon">
                                        <img
                                            src="@/assets/images/package-icon.svg"
                                            alt=""
                                        />
                                    </figure>
                                    <h2 class="type-title">{{ pack.name }}</h2>
                                    <ul class="type-specification">
                                        <li
                                            v-for="specification in pack.specifications"
                                            :key="specification.id"
                                        >
                                            <div class="dot"></div>
                                            <span> {{ specification.value }}</span>
                                        </li>
                                    </ul>
                                    <div class="type-price">
                                        <span>{{ $t("labels.startFrom") }}</span>
                                        <p>{{ pack.price }}</p>
                                    </div>
                                    <div class="get-service">
                                        <button @click="show()">{{ $t("labels.getIt") }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="package-types-5"
                            v-if="selectedSection.types.length === 5"
                        >
                            <div
                                v-for="pack in selectedSection.types"
                                :key="pack.id"
                                class="package-type-container"
                                :class="{
									'type-main': pack.is_main
								}"
                            >
                                <div class="package-type">
                                    <figure
                                        class="package-icon"
                                        :class="{ hidden: !pack.is_main }"
                                    >
                                        <img
                                            src="@/assets/images/package-icon.svg"
                                            alt=""
                                        />
                                    </figure>
                                    <h2 class="type-title">{{ pack.name }}</h2>
                                    <ul class="type-specification">
                                        <li
                                            v-for="specification in pack.specifications"
                                            :key="specification.id"
                                        >
                                            <div class="dot"></div>
                                            <span> {{ specification.value }}</span>
                                        </li>
                                    </ul>
                                    <div class="type-price">
                                        <span>{{ $t("labels.startFrom") }}</span>
                                        <p>{{ pack.price }}</p>
                                    </div>
                                    <div class="get-service">
                                        <button @click="show()">{{ $t("labels.getIt") }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section v-if="service.stages.length">
                <our-work-progress :stages="service.stages" />
            </section>
            <section class="flex">
                <button
                    v-if="!service.sections.length"
                    class="btn is-main mx-auto button"
                    @click="show()"
                >
                    {{ $t("labels.applyForService") }}
                </button>
                <template>
                    <modal
                        name="my-first-modal"
                        :scrollable="true"
                        :height="'auto'"
                        class="py-4"
                        :width="500"
                    >
                        <forms
                            :UploadFile="false"
                            Upload="Upload file"
                            message="Description (Optional) "
                            title="Order Now "
                            :Companyname="false"
                            Company="Company name "
                        />
                    </modal>
                </template>
            </section>
        </div>
    </div>
</template>
<script>
import Forms from "@/components/Forms.vue";
import OurWorkProgress from "../components/OurWorkProgress.vue";
import Tecnologies from "../components/Tecnologies.vue";
import Hero from "../components/Hero.vue";

export default {
    components: {
        Forms,
        OurWorkProgress,
        Tecnologies,
        Hero
    },

    data () {
        return {
            service: {
                title: "web",
                discription:
                    "Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specim",
                main_midea: "https://via.placeholder.com/300",
                tecnologies: [
                    { id: 1, midea: "@/assets/images/Hosting.svg", name: "photo shopp" },
                    {
                        id: 2,
                        midea: "@/assets/images/Hosting.svg",
                        name: "illestrator"
                    },
                    { id: 3, midea: "@/assets/images/Hosting.svg", name: "3d max" },
                    { id: 4, midea: "@/assets/images/Hosting.svg", name: "autocad" },
                    { id: 5, midea: "@/assets/images/Hosting.svg", name: "photo shop" },
                    { id: 6, midea: "@/assets/images/Hosting.svg", name: "illestrator" },
                    { id: 7, midea: "@/assets/images/Hosting.svg", name: "3d max" },
                    { id: 8, midea: "@/assets/images/Hosting.svg", name: "autocad" },
                    { id: 9, midea: "@/assets/images/Hosting.svg", name: "photo shop" },
                    { id: 10, midea: "@/assets/images/Hosting.svg", name: "illestrator" },
                    { id: 11, midea: "@/assets/images/Hosting.svg", name: "3d max" },
                    { id: 12, midea: "@/assets/images/Hosting.svg", name: "autocad" },
                    { id: 13, midea: "@/assets/images/Hosting.svg", name: "autocad" },
                    { id: 14, midea: "@/assets/images/Hosting.svg", name: "photo shopp" },
                    {
                        id: 15,
                        midea: "@/assets/images/Hosting.svg",
                        name: "illestrator"
                    },
                    { id: 16, midea: "@/assets/images/Hosting.svg", name: "3d max" },
                    { id: 17, midea: "@/assets/images/Hosting.svg", name: "autocad" },
                    { id: 18, midea: "@/assets/images/Hosting.svg", name: "photo shop" }
                ],
                sections: [
                    {
                        name: "section1",
                        id: 1,
                        discription:
                            "Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specim 1",
                        types: [
                            {
                                id: 1,
                                name: "pack1 1",
                                specifications: [
                                    {
                                        id: 1,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 2,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 3,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 4,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 5,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    }
                                ],
                                price: "2500 $/Y"
                            },
                            {
                                id: 2,
                                name: "pack2 1",
                                specifications: [
                                    {
                                        id: 1,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 2,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 3,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 4,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 5,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    }
                                ],
                                price: "2500 $/Y"
                            },
                            {
                                id: 3,
                                name: "pack3 1",
                                specifications: [
                                    {
                                        id: 1,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 2,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 3,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 4,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 5,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    }
                                ],
                                price: "2500 $/Y"
                            },
                            {
                                id: 4,
                                name: "pack4 1",
                                specifications: [
                                    {
                                        id: 1,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 2,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 3,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 4,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 5,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    }
                                ],
                                price: "2500 $/Y"
                            }
                        ]
                    },
                    {
                        name: "section2",
                        id: 2,
                        discription:
                            "Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specim 2",
                        types: [
                            {
                                id: 1,
                                name: "pack1 2",
                                specifications: [
                                    {
                                        id: 1,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 2,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 3,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 4,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 5,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    }
                                ],
                                price: "2500 $/Y"
                            },
                            {
                                id: 2,
                                name: "pack2 2",
                                specifications: [
                                    {
                                        id: 1,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 2,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 3,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 4,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 5,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    }
                                ],
                                price: "2500 $/Y"
                            },
                            {
                                id: 3,
                                name: "pack3 2",
                                specifications: [
                                    {
                                        id: 1,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 2,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 3,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 4,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 5,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    }
                                ],
                                price: "2500 $/Y"
                            },
                            {
                                id: 4,
                                name: "pack4 2",
                                specifications: [
                                    {
                                        id: 1,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 2,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 3,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 4,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 5,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    }
                                ],
                                price: "2500 $/Y"
                            },
                            {
                                id: 5,
                                name: "pack5 2",
                                is_main: true,
                                specifications: [
                                    {
                                        id: 1,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 2,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 3,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 4,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 5,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    }
                                ],
                                price: "2500 $/Y"
                            }
                        ]
                    },
                    {
                        name: "section3",
                        id: 3,
                        discription:
                            "Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specim 3",
                        types: [
                            {
                                id: 1,
                                name: "pack1 3",
                                specifications: [
                                    {
                                        id: 1,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 2,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 3,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 4,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 5,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    }
                                ],
                                price: "2500 $/Y"
                            },
                            {
                                id: 2,
                                name: "pack2 3",
                                specifications: [
                                    {
                                        id: 1,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 2,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 3,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 4,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 5,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    }
                                ],
                                price: "2500 $/Y"
                            },
                            {
                                id: 3,
                                name: "pack3 3",
                                specifications: [
                                    {
                                        id: 1,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 2,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 3,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 4,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    },
                                    {
                                        id: 5,
                                        key: "lorem-lorem-lorem",
                                        value: "lorem lorem lorem"
                                    }
                                ],
                                price: "2500 $/Y"
                            }
                        ]
                    }
                ],
                stages: []
            },
            selectedSection: null,
            chunk: [],
            chunks: []
        };
    },
    mounted () {
        this.selectedSection = this.service.sections[0];
        document
            .getElementById(`section-${this.selectedSection.id}`)
            .classList.add("selected");
        // this.setTecnologies();
    },
    methods: {
        show () {
            this.showin = true;
            this.$modal.show("my-first-modal");
        },
        hide () {
            this.$modal.hide("my-first-modal");
        },
        // setTecnologies() {
        // 	let num = 6;
        // 	this.service.tecnologies.map((tec, index) => {
        // 		this.chunk.push(tec);
        // 		if (this.chunk.length === num) {
        // 			this.chunks.push(this.chunk);
        // 			this.chunk = [];
        // 			num -= 1;
        // 		} else {
        // 			if (this.service.tecnologies.length - index === 1) {
        // 				this.chunks.push(this.chunk);
        // 			}
        // 		}
        // 	});
        // },
        changeSection (section) {
            this.selectedSection = section;
            const stories = document.querySelectorAll("li.section-details");
            for (let index = 0; index < stories.length; index++) {
                stories[index].classList.remove("selected");
            }
            document
                .getElementById(`section-${section.id}`)
                .classList.toggle("selected");
        }
    }
};
</script>
<style>
.vm--modal {
  border-top: solid 30px #ffd801;
  border-radius: 20px;
}
</style>
